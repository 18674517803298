import * as React from 'react'
import { useLocation } from '@reach/router'
import { compose, get, map } from 'lodash/fp'
import isEmpty from 'lodash/isEmpty'
import { graphql, Link as GatsbyLink } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { MdCheckCircle } from 'react-icons/md'

import { useCartContext } from 'context/CartContext'
import Layout from 'src/components/Layouts'
import { localize } from 'src/components/Localize'
import Container from 'src/components/Container'
import Headings from 'src/components/Headings'
import Button from '@/components/Button'
import { Background } from '@/components/Slide'
import Spinner from '@/components/Spinner'

import { formatSessionAmount } from '@/utils/helpers'

import receiptBackground from 'src/images/receipt.jpeg'

const Success = ({ pageContext, data }) => {
  const { locale } = pageContext

  const [session, setSession] = React.useState({})
  const location = useLocation()
  const { cartDispatch } = useCartContext()
  const sessionId = location.search.replace('?session_id=', '')

  const menuItems = compose(map('node'), get('allSanityMenus.edges'))(data)
  const siteGlobal = compose(map('node._rawContent'), get('allSanitySiteGlobal.edges'))(data)

  const navMenuItems = menuItems.map((menuItem) => ({
    ...menuItem,
    items: menuItem.items.map((item) => ({
      ...item,
      link: get('link.content.main.slug.current', item),
    })),
  }))

  React.useEffect(() => {
    async function fetchSession() {
      setSession(
        await fetch(`/.netlify/functions/stripe-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId,
          }),
        }).then((res) => res.json())
      )

      cartDispatch({
        type: 'CLEAR_CART',
      })
    }
    fetchSession()
  }, [sessionId, cartDispatch])

  console.log('session', session)
  return (
    <Layout navMenuItems={navMenuItems} siteGlobal={siteGlobal[0]} locale={locale}>
      <Container>
        <Background
          absolute
          css={css`
            background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(0, 0, 0, 0.1)),
              url(${receiptBackground}) no-repeat center center;
            height: 100%;
          `}
        />

        <Grid>
          <Item background>
            {isEmpty(session) ? (
              <Spinner />
            ) : (
              <>
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  <MdCheckCircle
                    style={{
                      color: '#00a8a6',
                      height: '50px',
                      width: '50px',
                      marginBottom: 20,
                    }}
                  />

                  <Headings.h3>
                    {locale === 'tr' ? 'Bağışınız Tamamlandı!' : 'Donasjonen er fullført!'}
                  </Headings.h3>

                  {locale === 'tr' ? (
                    <Headings.HeadSubtitle
                      css={css`
                        margin-top: 2rem;
                        margin-bottom: 1rem;
                      `}
                    >
                      <span
                        css={css`
                          color: #000;
                          font-weight: 600;
                        `}
                      >
                        {formatSessionAmount(session.session?.amount_total)}
                      </span>{' '}
                      bağışınız başarıyla işleme alındı.{' '}
                      <span
                        css={css`
                          color: #000;
                          font-weight: 600;
                        `}
                      >
                        {session?.session?.customer?.email}
                      </span>{' '}
                      e-posta adresinize bağış makbuzunuz gönderildi.
                    </Headings.HeadSubtitle>
                  ) : (
                    <Headings.HeadSubtitle
                      css={css`
                        margin-top: 2rem;
                        margin-bottom: 1rem;
                      `}
                    >
                      Donasjonen din på{' '}
                      <span
                        css={css`
                          color: #000;
                          font-weight: 600;
                        `}
                      >
                        {formatSessionAmount(session.session?.amount_total)}
                      </span>{' '}
                      er behandlet. En e-post med kvittering er sendt til{' '}
                      <span
                        css={css`
                          color: #000;
                          font-weight: 600;
                        `}
                      >
                        {session?.session?.customer?.email}.
                      </span>{' '}
                    </Headings.HeadSubtitle>
                  )}
                </div>

                <div>
                  <ExternalLink
                    target="_blank"
                    rel="noopener noreferrer"
                    data-a11y="false"
                    aria-label={`Link to ${session?.session?.payment_intent?.charges?.data[0]?.receipt_url}`}
                    href={session?.session?.payment_intent?.charges?.data[0]?.receipt_url}
                    css={css`
                      transition: background 0.1s ease-in-out;
                      background: #000;
                      color: #fff;
                      border-color: #c8c8c8;
                      margin-right: 10px;
                      font-weight: 500;

                      &:hover {
                        background: #342f2f;
                      }
                    `}
                  >
                    {locale === 'tr' ? `Makbuzu yazdırın` : `Skriv ut kvittering`}
                  </ExternalLink>
                  <Button component={Link} to={`/`}>
                    {locale === 'tr' ? `Anasayfaya geri dön` : `Gå tilbake til hjemmesiden`}
                  </Button>
                </div>
              </>
            )}
          </Item>
        </Grid>
      </Container>
    </Layout>
  )
}

export default localize(Success)

const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  margin-top: 0.5rem;
  height: calc(100vh - 5rem);
`

const Item = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem; */
  padding: 6rem 4rem;
  flex: 0 0 70%;
  max-width: 70%;
  text-align: center;
  /* text-align: center; */

  ${({ background }) =>
    background &&
    `
  background: #f4fafb;

  `}
`

const Link = styled(GatsbyLink)`
  color: ${(p) => p.theme.colors.white};
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 29px;
  padding: 12px 29px;
  text-align: center;
  transition: background 0.1s ease-in-out;
  background: #000;

  &:hover {
    background: #342f2f;
  }
`

const ExternalLink = styled.a`
  color: ${(p) => p.theme.colors.white};
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 29px;
  padding: 12px 29px;
  text-align: center;
  transition: background 0.1s ease-in-out;
  background: #000;

  &:hover {
    background: #342f2f;
  }
`

export const query = graphql`
  query SuccessTemplateQuery {
    allSanitySiteGlobal {
      edges {
        node {
          _rawContent
        }
      }
    }
    allSanityMenus {
      edges {
        node {
          menuLocation {
            mainMenu
            footerMenu
          }
          items {
            ... on SanityInternalLink {
              title {
                no
                tr
                _type
              }
              link {
                content {
                  main {
                    slug {
                      _type
                      tr {
                        current
                        _type
                      }
                      no {
                        current
                        _type
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            no
            tr
            _type
          }
          slug {
            _type
            tr {
              current
              _type
            }
            no {
              current
              _type
            }
          }
        }
      }
    }
  }
`
